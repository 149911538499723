<template>
  <div class="layout-border">
    <div class="mainCont kids_dragStrokeToImagePuzzle">
      <div class="background">
        <div class="backgroundImg_n_DragArea">
          <div class="imageWrapper">
            <img class="objectDisplay" :src="ObjectList[question].image" />
          </div>
          <div class="bottonsWrapper">
            <div
              v-for="(item, index) in chineseList"
              :key="index"
              class="content-words-two"
              @click="foundAnswer(index)"
            >
              <span class="font-pinyin-medium pinyin">{{ item.pinying }}</span>
              <span class="font-hanzi-medium">{{ item.hanzi }}</span>
            </div>
          </div>
        </div>

        <div class="strokesFoundBoxes">
          <div class="outerBox">
            <img
              v-if="answersfound > 0"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
          <div class="outerBox">
            <img
              v-if="answersfound > 1"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
          <div class="outerBox">
            <img
              v-if="answersfound > 2"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
          <div class="outerBox">
            <img
              v-if="answersfound > 3"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
          <div class="outerBox">
            <img
              v-if="answersfound > 4"
              class="strokesFoundimage"
              :src="strockFoundImage"
            />
            <img v-else class="strokesFoundimage" :src="strockNotFoundImage" />
          </div>
        </div>
      </div>
    </div>
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :numBers="answersfound"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
      @updateNumbers="updateNumbers($event)"
    ></PageButton>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  components: {
    PageButton,
  },

  props: {
    ObjectList: {
      type: Array,
      require: true,
      default: () => [],
    },
    chineseList: {
      type: Array,
      require: true,
      default: () => [],
    },
  },
  data() {
    return {
      answersfound: 0,
      isLastStep: false,

      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 5,
            numBerValueAfterClick: null,
          },
          {
            type: "next",
            startNumberRange: 5,
            endNumberRange: 6,
            numBerValueAfterClick: 6,
          },
        ],
      },

      question: 0,

      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),
      wrong_audio_sound: require("@/assets/audio/wrong_answer.mp3"),
      strockNotFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G21-puzzle/star-complete.svg"),
      objectDisplay: "",
      socketInfo: {},
      foundAnswerValue: 0,
    };
  },
  mounted() {
    this.$bus.$on("RecognitionNumberClick", ({ index }) => {
      this.foundAnswer(index, true);
    });
  },
  beforeDestroy(){
     this.$bus.$off("RecognitionNumberClick")
  },
  watch: {
    socketInfo: {
      handler(value) {
        const data = {
          clickType: 20019,
          data: { value},
          text: "RecognitionNumberGame点击socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  methods: {
    foundAnswer(index, isFromSocket=false) {
      if (!isFromSocket) {
          this.socketInfo = {
        index,
        value: Math.random(),
      };
        this.foundAnswerValue++;
      }
    
      //  let elementList = this.$refs[`SI_${this.draggedStroke}`];
      console.log(
        "button clicked 1:" +
          this.chineseList[index].number +
          " 2: " +
          this.ObjectList[this.question].number
      );
      if (
        this.chineseList[index].number == this.ObjectList[this.question].number
      ) {
        //  this.objectDisplay = this.ObjectList[this.question].image
        // this.ObjectList[this.question].showObj = false;
        this.answersfound++;
        this.timer = setTimeout(() => {
          if (this.question + 1 < this.ObjectList.length) {
            this.question++;
            this.objectDisplay = "";
          } else {
            this.isLastStep = true;
          }
        }, 1000);
        if (this.answersfound == 5) {
          this.isLastStep = true;
          playCorrectSound();
          startConfetti();
        } else {
          playCorrectSound(true, false);
        }
      } else {
        playCorrectSound(false);
      }

    },

    nextPage() {
      this.$bus.$emit("nextButton", true);
    },

    updateNumbers(value) {
      this.numBers = value;
    },
    activeClick() {
      let readWords = document.getElementById("face").innerText;
      var url =
        "http://tts.baidu.com/text2audio?lan=zh&ie=UTF-8&text=" +
        encodeURI(readWords);
      var n = new Audio(url);
      n.src = url;
      n.play();
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  background: #fff;
}
.timer {
  position: absolute;
  top: 10%;
  height: 80%;
  left: 5%;
  .clock_cont {
    height: 85%;
    position: absolute;
    left: 31%;
    width: 40%;
    /* margin-left: -3px; */
    top: 4%;
    .clock {
      background-color: #f4d737;
      height: 100%;
      position: absolute;
      left: 0;
      width: 100%;
      /* margin-left: -3px; */
      bottom: 0;
    }
  }

  .time {
    z-index: 5;
    height: 100%;
  }
}

.bottonsWrapper {
  display: flex;
  bottom: 0;
  height: -moz-fit-content;
  justify-content: center;
  height: 30%;
  width: 80%;
  position: absolute;
  left: 10%;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  //background-color: #FFFFFF;
  justify-content: space-evenly;
  align-items: center;
}

.imageWrapper {
  position: absolute;
  width: 90%;
  height: 60%;
  background: #ffffff;
  top: 5%;
  left: 5%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .objectDisplay {
  // position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // top: 30%;
  // left: 15%;
}
}

.content-words-two {
  height: 10vh;
  width: 10vh;
  z-index: 1;
  border-radius: 15px;
  background-color: #cd4c3f;
  border-bottom: 8px solid #ac3d32;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 4px;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}
.content-words-two:active {
  transform: scale(0.95);
}
.content-words-two:hover {
  transform: scale(1.1);
  border-radius: 15px;
  background-color: #912016;
  border-top: 8px solid #6e0c03;
  border-bottom: 0px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  span {
    color: #fff;
    font-size: 25px;
  }
  span:nth-child(2) {
    color: #fff;
    font-size: 30px;
  }
}

.txt-py {
  // very large screen
  font-size: 2vh !important;
  padding-top: 10px;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 2vh !important;
    padding-top: 5px;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 2vh !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 2vh !important;
  }
}

.txt-hz {
  // very large screen
  font-size: 3vh !important;
  @media screen and (max-width: 2200px) {
    // external screen
    font-size: 3vh !important;
  }
  @media screen and (max-width: 1500px) {
    // laptop
    font-size: 3vh !important;
  }
  @media screen and (max-width: 1300px) {
    // ipad
    font-size: 3vh !important;
  }
}


.plate {
  bottom: 10%;
  position: absolute;
  width: 60%;
  left: 20%;
}
.text_hanzi_top {
  width: 100%;
  height: 25%;
  left: 0;
  position: absolute;
  background: #224e96;
  text-align: center;
  color: #fff;
  .pinyin {
    margin-top: 20px;
    font-family: "STHeitiSCPinyin-Light";
  }
  :hover {
    background: #1b4589;
  }
}
.objListBackground {
  position: absolute;
  width: 100%;
}
.objectList {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 15%;
  height: 55%;
  //  background-image: url("../../../common/img/lesson3/5-DRAG_N_DROP_TO_PLATE/shape.svg");
  top: 17%;
  left: 10%;
  .strokeImage {
    width: 80%;
    height: 20%;
    -webkit-user-drag: auto;
    z-index: 10;
    margin-top: 25%;
    margin-left: 10%;
  }
}
.mainCont {
  width: 100%;
  height: 100%;
  // height: 100%;
  // margin-top: 15px;
  // margin-left: 15px;
  // background-image: url("../../../common/img/lesson3/5-DRAG_N_DROP_TO_PLATE/background.svg");
  background: #fff;
  border-radius: 48px;
  position: relative;
  overflow: hidden;
}
.background {
  text-align: -webkit-center;
  position: absolute;
  width: 100%;
  height: 100%;
  // height: 96%;
  // width: 98%;
  // top: 1%;
  // left: 1%;
  background-image: url("../../../../assets/img/03-Backgrounds/background-practice.svg");
  border-radius: 48px;
  //  top: 15%;
  //   left: 15%;
  margin: auto;
  overflow: hidden;
}
.backgroundImg_n_DragArea {
  position: relative;
  width: -moz-fit-content;
  background-color: #214e96;
  width: 70%;
  height: 75%;
  top: 12%;
  /* left: 10%; */
  flex-direction: column;
  border-radius: 30px;
  display: flex;

  // img {
  //   position: relative;
  //   height: 100%;
  //   text-align: -webkit-center;
  // }
}
.images {
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  border-radius: 10%;
  img {
    position: relative;
    height: 100%;
    text-align: -webkit-center;
  }
}

.strokesFoundBoxes {
  position: absolute;
  right: 11%;
  top: 0;
  height: fit-content;
  padding: 5px 25px 15px 25px;
  display: flex;
  flex-direction: row;
  background-color: #f4d737;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom: 10px solid #d1b726;
  .strokesFoundimage {
    height: 100%;
    width: 27px;
    margin-top: 20%;
  }
  .outerBox {
    width: 100%;
    height: 100%;
    padding: 0px 4px;
    position: relative;
    display: flex;

    .innerBox {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #2472B3;
    }

    .innerBoxFound {
      width: 80%;
      height: 80%;
      margin: 10%;
      border-radius: 15px;
      // background-color: #F3D438;

      .strokesFoundimage {
        height: 65%;
        margin-top: 30%;
        // margin-left: 40%;
        margin-right: 5%;
      }
    }
  }
}
.star {
  display: none;

  img {
    // width: 20px;
    //  height: 20px;
    // display: none;

    &.animatedstar {
      display: block;
      -webkit-animation: spin 1s linear infinite;
      -moz-animation: spin 1s linear infinite;
      animation: spin 1s linear infinite;
      -webkit-animation-iteration-count: 1;
    }
  }
}
.stroke1 {
  display: block;
  position: absolute;
  width: 100%;
  height: 65%;
  bottom: 5%;
  left: 0;
  z-index: 99;
  &:hover {
    cursor: pointer;
  }
}
.stroke2 {
  display: block;
  position: absolute;
  //  background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  top: 0%;
  left: 34%;
  &:hover {
    cursor: pointer;
  }
}

.stroke3 {
  display: block;
  position: absolute;
  //   background-color: black;
  z-index: 99;
  width: 34%;
  height: 33%;
  top: 34%;
  left: 33%;
  &:hover {
    cursor: pointer;
  }
}
.stroke4 {
  display: block;
  position: absolute;
  //  background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  top: 33%;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}
.stroke5 {
  display: block;
  position: absolute;
  //       background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  bottom: 0;
  left: 0;
  &:hover {
    cursor: pointer;
  }
}
.stroke6 {
  display: block;
  position: absolute;
  //       background-color: black;
  z-index: 99;
  width: 34%;
  height: 34%;
  bottom: 0;
  right: 0;
  &:hover {
    cursor: pointer;
  }
}

.confettiCanvas {
  z-index: 900;
  position: absolute;
}
</style>
